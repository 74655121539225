import a from "react";
var O = Object.prototype.hasOwnProperty;
function U(e, t, n) {
  for (n of e.keys())
    if (R(n, t)) return n;
}
function R(e, t) {
  var n, r, s;
  if (e === t) return !0;
  if (e && t && (n = e.constructor) === t.constructor) {
    if (n === Date) return e.getTime() === t.getTime();
    if (n === RegExp) return e.toString() === t.toString();
    if (n === Array) {
      if ((r = e.length) === t.length)
        for (; r-- && R(e[r], t[r]); ) ;
      return r === -1;
    }
    if (n === Set) {
      if (e.size !== t.size)
        return !1;
      for (r of e)
        if (s = r, s && typeof s == "object" && (s = U(t, s), !s) || !t.has(s)) return !1;
      return !0;
    }
    if (n === Map) {
      if (e.size !== t.size)
        return !1;
      for (r of e)
        if (s = r[0], s && typeof s == "object" && (s = U(t, s), !s) || !R(r[1], t.get(s)))
          return !1;
      return !0;
    }
    if (n === ArrayBuffer)
      e = new Uint8Array(e), t = new Uint8Array(t);
    else if (n === DataView) {
      if ((r = e.byteLength) === t.byteLength)
        for (; r-- && e.getInt8(r) === t.getInt8(r); ) ;
      return r === -1;
    }
    if (ArrayBuffer.isView(e)) {
      if ((r = e.byteLength) === t.byteLength)
        for (; r-- && e[r] === t[r]; ) ;
      return r === -1;
    }
    if (!n || typeof e == "object") {
      r = 0;
      for (n in e)
        if (O.call(e, n) && ++r && !O.call(t, n) || !(n in t) || !R(e[n], t[n])) return !1;
      return Object.keys(t).length === r;
    }
  }
  return e !== e && t !== t;
}
const j = (e, t) => {
  const n = a.useRef(t);
  return R(n.current, t) || (n.current = t), a.useCallback(e, n.current);
}, v = (e) => (t) => {
  const [n] = t.data;
  return Promise.resolve(e.fn(...n)).then((r) => {
    const s = (i) => "ArrayBuffer" in self && i instanceof ArrayBuffer || "MessagePort" in self && i instanceof MessagePort || "ImageBitmap" in self && i instanceof ImageBitmap || "OffscreenCanvas" in self && i instanceof OffscreenCanvas, k = e.transferable === "auto" && s(r) ? [r] : [];
    postMessage(["SUCCESS", r], k);
  }).catch((r) => {
    postMessage(["ERROR", r]);
  });
}, W = (e) => e.length === 0 ? "" : `importScripts(${e.map((n) => `'${n}'`).toString()})`, G = (e, t, n) => {
  const r = `
    ${W(t)};
    onmessage=(${v})({
      fn: (${e}),
      transferable: '${n}'
    })
  `, s = new Blob([r], { type: "text/javascript" });
  return URL.createObjectURL(s);
};
var c = /* @__PURE__ */ ((e) => (e.PENDING = "PENDING", e.SUCCESS = "SUCCESS", e.RUNNING = "RUNNING", e.ERROR = "ERROR", e.TIMEOUT_EXPIRED = "TIMEOUT_EXPIRED", e.KILLED = "KILLED", e))(c || {});
const b = "resolve", C = "reject", m = {
  timeout: void 0,
  remoteDependencies: [],
  autoTerminate: !0,
  transferable: "auto"
  /* AUTO */
}, A = (e, t = m) => {
  const [n, r] = a.useState(
    c.PENDING
  ), s = a.useRef(), k = a.useRef(!1), i = a.useRef({}), y = a.useRef(), f = a.useCallback(() => {
    var u;
    (u = s.current) != null && u._url && (s.current.terminate(), URL.revokeObjectURL(s.current._url), i.current = {}, s.current = void 0, window.clearTimeout(y.current));
  }, []), h = a.useCallback(
    (u) => {
      (t.autoTerminate != null ? t.autoTerminate : m.autoTerminate) && f(), r(u);
    },
    [t.autoTerminate, f, r]
  ), D = j(() => {
    const {
      remoteDependencies: u = m.remoteDependencies,
      timeout: l = m.timeout,
      transferable: p = m.transferable
    } = t, I = G(e, u, p), g = new Worker(I);
    return g._url = I, g.onmessage = (E) => {
      var S, d, L, P;
      const [o, w] = E.data;
      switch (o) {
        case c.SUCCESS:
          (d = (S = i.current)[b]) == null || d.call(S, w), h(c.SUCCESS);
          break;
        default:
          (P = (L = i.current)[C]) == null || P.call(L, w), h(c.ERROR);
          break;
      }
    }, g.onerror = (E) => {
      var o, w;
      (w = (o = i.current)[C]) == null || w.call(o, E), h(c.ERROR);
    }, l && (y.current = window.setTimeout(() => {
      f(), r(c.TIMEOUT_EXPIRED);
    }, l)), g;
  }, [e, t, f]), N = a.useCallback(
    (...u) => {
      const { transferable: l = m.transferable } = t;
      return new Promise((p, I) => {
        var E;
        i.current = {
          [b]: p,
          [C]: I
        };
        const g = l === "auto" ? u.filter(
          (o) => "ArrayBuffer" in window && o instanceof ArrayBuffer || "MessagePort" in window && o instanceof MessagePort || "ImageBitmap" in window && o instanceof ImageBitmap || "OffscreenCanvas" in window && o instanceof OffscreenCanvas
        ) : [];
        (E = s.current) == null || E.postMessage([[...u]], g), r(c.RUNNING);
      });
    },
    [r]
  ), M = a.useCallback(
    (...u) => {
      const l = t.autoTerminate != null ? t.autoTerminate : m.autoTerminate;
      return k.current ? (console.error(
        "[useWorker] You can only run one instance of the worker at a time, if you want to run more than one in parallel, create another instance with the hook useWorker(). Read more: https://github.com/alewin/useWorker"
      ), Promise.reject()) : ((l || !s.current) && (s.current = D()), N(...u));
    },
    [t.autoTerminate, D, N]
  ), T = a.useCallback(() => {
    f(), r(c.KILLED);
  }, [f, r]), B = {
    status: n,
    kill: T
  };
  return a.useEffect(() => {
    k.current = n === c.RUNNING;
  }, [n]), a.useEffect(
    () => () => {
      f();
    },
    [f]
  ), [M, B];
};
export {
  c as WORKER_STATUS,
  A as useWorker
};
